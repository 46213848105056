import { TIME_SECTION_ENUM } from 'app/SchedulerDashboard/utils/constants';
import moment from 'moment';

export const generateClassNameForContentPlacement = (placement) => {
  return `d-flex align-items-center flex-wrap justify-content-${placement} flex-sm-shrink-0`;
};

const TIME_FORMAT = 'h:mm';

export const setTimesForRender = (currDate, timeRange, timeSectionEnum) => {
  const getAvailableTimes = timeRange
    .filter((time) => {
      if (timeSectionEnum === TIME_SECTION_ENUM.MORNING) {
        return moment(time).isBefore(moment(`${currDate} 12:00`));
      }
      if (timeSectionEnum === TIME_SECTION_ENUM.AFTERNOON) {
        return moment(time).isSameOrAfter(moment(`${currDate} 12:00`));
      }
    })
    .map((time) => {
      const timeKey = moment(time).format(TIME_FORMAT);
      return { label: timeKey, value: time };
    });
  return getAvailableTimes;
};
