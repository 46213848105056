import React, { Fragment, useEffect, useState } from 'react';
import { ChevronRight, ChevronLeft } from 'react-feather';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { useApiClientContext } from 'ApiClientProvider';
import { SCREENS, NOW_LOADING, END_LOADING } from 'app/SchedulerDashboard/utils/constants';
import { PREV, NEXT } from 'app/SchedulerDashboard/utils/constants';
import { generateClassNameForContentPlacement } from 'app/SchedulerDashboard/utils/dashboardUtils';
import cloneDeep from 'lodash/cloneDeep';
import { Button, Col, Container, Row, FormGroup, Label } from 'reactstrap';
import { showErrorToast } from 'toast';

const VehicleInformation = ({ setActiveScreen, currDataState, setCurrDataState }) => {
  const dispatch = useDispatch();
  const { vehicleInformation } = currDataState;
  const [currYear, setCurrYear] = useState(vehicleInformation.currYear ?? null);
  const [currMake, setCurrMake] = useState(vehicleInformation.currMake ?? null);
  const [currModel, setCurrModel] = useState(vehicleInformation.currModel ?? null);
  const [yearSelection, setYearSelection] = useState([]);
  const [makeSelection, setMakeSelection] = useState([]);
  const [modelSelection, setModelSelection] = useState([]);
  const { schedulerApi } = useApiClientContext();

  useEffect(() => {
    handleKBBCalls('/kbb/kbb-year', setYearSelection, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currYear !== null) {
      handleKBBCalls(`/kbb/kbb-make?year=${currYear}`, setMakeSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currYear]);

  useEffect(() => {
    if (currMake !== null) {
      handleKBBCalls(`/kbb/kbb-model?make=${currMake}&year=${currYear}`, setModelSelection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currMake]);

  const handleKBBCalls = async (url, setHookFunc, reverse = false) => {
    dispatch({ type: NOW_LOADING });
    try {
      const response = await schedulerApi.get(url);
      setHookFunc(reverse ? response.data.slice().reverse() : response.data);
    } catch (err) {
      showErrorToast(err);
    } finally {
      dispatch({ type: END_LOADING });
    }
  };

  const handleWindowChange = (window) => {
    const currStateDeepCopy = cloneDeep(currDataState);
    currStateDeepCopy.vehicleInformation = {
      currYear,
      currMake,
      currModel,
    };
    setCurrDataState(currStateDeepCopy);
    if (window === PREV) {
      setActiveScreen(SCREENS.carSelection);
    } else if (window === NEXT) {
      setActiveScreen(SCREENS.customerInformation);
    }
  };

  return (
    <Fragment>
      <Container className="pb-4">
        <Col className="text-center">
          <h1 className="pb-1">Enter your vehicle information</h1>
          <h5 className="font-weight-light">We service all makes and models.</h5>
        </Col>
        <Row>
          <Col>
            <FormGroup>
              <Label className="h6">Year</Label>
              <Select
                placeholder="Select"
                value={yearSelection.find((item) => item.year === currYear)}
                isSearchable={true}
                options={yearSelection}
                getOptionLabel={(item) => item.year}
                getOptionValue={(item) => item.year}
                onChange={(item) => {
                  setCurrYear(item.year);
                  setCurrMake(null);
                  setCurrModel(null);
                }}
              />
            </FormGroup>
            {currYear && (
              <FormGroup>
                <Label className="h6">Make</Label>
                <Select
                  placeholder="Select"
                  value={currMake ? makeSelection.find((item) => item.make === currMake) : ''}
                  isSearchable={true}
                  options={makeSelection}
                  getOptionLabel={(item) => item.make}
                  getOptionValue={(item) => item.make}
                  onChange={(item) => {
                    setCurrMake(item.make);
                    setCurrModel(null);
                  }}
                />
              </FormGroup>
            )}
            {currMake && (
              <FormGroup>
                <Label className="h6">Model</Label>
                <Select
                  placeholder="Select"
                  value={currModel ? modelSelection.find((item) => item.model === currModel) : ''}
                  isSearchable={true}
                  options={modelSelection}
                  getOptionLabel={(item) => item.model}
                  getOptionValue={(item) => item.model}
                  onChange={(item) => {
                    setCurrModel(item.model);
                  }}
                />
              </FormGroup>
            )}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col className={generateClassNameForContentPlacement('start')}>
            <Button
              className="nav-btn px-2"
              outline
              color="primary"
              onClick={() => handleWindowChange(PREV)}
            >
              <ChevronLeft size={16} /> Back
            </Button>
          </Col>
          <Col className={generateClassNameForContentPlacement('end')}>
            <Button
              className="nav-btn next px-2"
              disabled={!currMake || !currModel || !currYear}
              color="success"
              onClick={() => handleWindowChange(NEXT)}
            >
              <span
                style={{
                  paddingRight: '10px',
                }}
              >
                Next
              </span>
              <ChevronRight size={16} />
            </Button>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default VehicleInformation;
