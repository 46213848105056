import React from 'react';
import { Card, CardBody } from 'reactstrap';
import CustomCollapseItem from 'components/custom/CustomCollapseItem';
import 'assets/scss/CustomCollapse.scss';

const CustomCollapse = ({
  collapseItems,
  handleUpdateService,
  handleAddToSelectedService,
  handleRemoveService,
}) => {
  return (
    <Card className="items-container">
      <CardBody>
        <div className="vx-collapse collapse-bordered">
          {collapseItems.map((collapseItem) => {
            return (
              <CustomCollapseItem
                key={collapseItem.id}
                collapseItem={collapseItem}
                handleUpdateService={handleUpdateService}
                handleAddToSelectedService={handleAddToSelectedService}
                handleRemoveService={handleRemoveService}
              />
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};

export default CustomCollapse;
