import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { ChevronRight, ChevronLeft } from 'react-feather';
import { useDispatch } from 'react-redux';
import { useApiClientContext } from 'ApiClientProvider';
import {
  SCREENS,
  PREV,
  NEXT,
  NOW_LOADING,
  END_LOADING,
} from 'app/SchedulerDashboard/utils/constants';
import { generateClassNameForContentPlacement } from 'app/SchedulerDashboard/utils/dashboardUtils';
import { validateEmail } from 'app/SchedulerDashboard/utils/validations';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import {
  Button,
  Card,
  CardBody,
  Container,
  Row,
  Col,
  Input,
  Label,
  FormGroup,
  Alert,
} from 'reactstrap';
import { showErrorToast } from 'toast';

const TEXT = 'text';
const EMAIL = 'email';
const NAME = 'name';
const PHONE = 'phone';

const CustomerInformation = ({ setActiveScreen, currDataState, setCurrDataState }) => {
  const dispatch = useDispatch();
  const { customerInformation } = currDataState;
  const [customer, setCustomer] = useState(
    !isEmpty(customerInformation.customer) ? customerInformation.customer : {},
  );
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [customerName, setCustomerName] = useState(customerInformation.customerName ?? '');
  const [customerPhone, setCustomerPhone] = useState(customerInformation.customerPhone ?? '');
  const [customerEmail, setCustomerEmail] = useState(customerInformation.customerEmail ?? '');
  const [message, setMessage] = useState(null);
  const { schedulerApi } = useApiClientContext();

  const dispatchLoading = () => dispatch({ type: NOW_LOADING });
  const dispatchFinishLoading = () => dispatch({ type: END_LOADING });
  const setUserDataFromResponse = (response) => {
    const { data } = response;
    const { name, email } = data;
    setCustomer(data);
    setCustomerName(name);
    setCustomerEmail(email);
  };
  const phoneInputLength = useMemo(() => {
    return customerPhone.startsWith('1') ? 11 : 10;
  }, [customerPhone]);

  const handleWindowChange = (window) => {
    const currDataStateDeepCopy = cloneDeep(currDataState);
    currDataStateDeepCopy.customerInformation = {
      customerName,
      customerPhone,
      customerEmail,
      customer,
    };
    setCurrDataState(currDataStateDeepCopy);
    if (window === PREV) {
      setActiveScreen(SCREENS.vehicleInformation);
    } else if (window === NEXT) {
      setActiveScreen(SCREENS.review);
    }
  };

  const handleFindCustomerByPhone = async () => {
    setCustomerName('');
    setCustomerEmail('');
    dispatchLoading();
    try {
      const response = await schedulerApi.get(`/customer/find-by-phone?phone=${customerPhone}`);
      if (response.status === 200) {
        setUserDataFromResponse(response);
        setMessage({
          content: 'Your information is already in our system. Please press "Next" to continue.',
          color: 'success',
        });
      }
    } catch (error) {
      setCustomer({});
      setIsNewCustomer(true);
      setCustomerName(customerName);
      setCustomerEmail(customerEmail);
      // showErrorToast({
      //   message:
      //     'Customer can not be found, please enter a correct phone number or fill in the information to enter as a new customer',
      // });
    } finally {
      dispatchFinishLoading();
    }
  };

  const handleAddNewCustomer = async () => {
    if (!customerPhone || !customerName) {
      return setMessage({
        content: 'Please fill in all the fields',
        color: 'danger',
      });
    }

    if (customerEmail && !validateEmail(customerEmail)) {
      showErrorToast({
        message: `Email is not a valid email`,
      });

      return;
    }

    dispatchLoading();
    try {
      const response = await schedulerApi.post('/customer', {
        name: customerName,
        phone: customerPhone,
        email: customerEmail,
      });
      setMessage({
        content: 'Your information is already in our system. Please press “Next” to continue.',
        color: 'success',
      });
      setUserDataFromResponse(response);
      setIsNewCustomer(false);
    } catch (error) {
      setMessage({
        content: error.response?.data?.message ?? error.message,
        color: 'danger',
      });
      await handleFindCustomerByPhone();
    } finally {
      dispatchFinishLoading();
    }
  };

  const handleNumberOnlyKeyPress = useCallback((event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === NAME) {
      setCustomerName(value);
    } else if (name === EMAIL) {
      setCustomerEmail(value);
    } else if (name === PHONE) {
      setCustomerPhone(value);
    }
  };

  // const handleResetCustomerValues = () => {
  //   setIsNewCustomer(false);
  //   setCustomer({});
  //   setCustomerName('');
  //   setCustomerEmail('');
  //   setCustomerPhone('');
  // };

  return (
    <Fragment>
      <Card>
        <CardBody>
          <Container>
            <Row>
              <Col className={generateClassNameForContentPlacement('center')}>
                <h1 style={{ marginBottom: '1rem' }}>Enter Your Contact Phone</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label htmlFor={PHONE} className="h6 font-weight-light">
                    Phone*
                  </Label>
                  <Row>
                    <Col xs="8">
                      <Input
                        id={PHONE}
                        name={PHONE}
                        type="tel"
                        maxLength={phoneInputLength}
                        minLength={phoneInputLength}
                        value={customerPhone}
                        onKeyPress={handleNumberOnlyKeyPress}
                        onChange={handleInputChange}
                        required
                        placeholder="Please enter phone number."
                      />
                    </Col>
                    <Col className={generateClassNameForContentPlacement('end')} xs="4">
                      <Button
                        block
                        disabled={customerPhone.length !== phoneInputLength}
                        color="primary"
                        onClick={async () => {
                          setMessage(null);
                          await handleFindCustomerByPhone();
                        }}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            {message && (
              <Row>
                <Col>
                  <Alert color={message.color} className="text-center">
                    <p style={{ fontSize: 18, margin: 0 }}>{message.content}</p>
                  </Alert>
                </Col>
              </Row>
            )}
            {isEmpty(customer) && isNewCustomer ? (
              <Fragment>
                <Row>
                  <Col>
                    <Alert color="warning" className="text-center">
                      <p style={{ fontSize: 18, margin: 0 }}>
                        Welcome to your first visit. Please enter your information below.
                      </p>
                    </Alert>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor={NAME} className="h6 font-weight-light">
                        Name*
                      </Label>
                      <Input
                        id={NAME}
                        name={NAME}
                        type={TEXT}
                        value={customerName}
                        onChange={handleInputChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label htmlFor={EMAIL} className="h6 font-weight-light">
                        Email*
                      </Label>
                      <Input
                        id={EMAIL}
                        name={EMAIL}
                        type={EMAIL}
                        value={customerEmail}
                        onChange={handleInputChange}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Fragment>
            ) : (
              <Fragment>
                {customer.name && (
                  <Row className="mt-2">
                    <Col>
                      <p style={{ marginBottom: 0 }}>
                        <span style={{ color: '#82868B', fontWeight: 300 }}>Name:</span>{' '}
                        <span style={{ color: '#28C76F', fontWeight: 700 }}>{customer.name}</span>
                      </p>
                    </Col>
                  </Row>
                )}
                {customer.email && (
                  <Row>
                    <Col>
                      <p>
                        <span style={{ color: '#82868B', fontWeight: 300 }}>Email:</span>{' '}
                        <span style={{ color: '#28C76F', fontWeight: 700 }}>{customer.email}</span>
                      </p>
                    </Col>
                  </Row>
                )}
              </Fragment>
            )}
            {/* Commented out because Joe requested that we remove */}
            {/* <Row className="py-1">
              <Col className={generateClassNameForContentPlacement('center')}>
                <Button className="nav-btn" color="secondary" onClick={handleResetCustomerValues}>
                  Restart
                </Button>
              </Col>
            </Row> */}
          </Container>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Container>
            <Row>
              <Col className={generateClassNameForContentPlacement('start')}>
                <Button
                  className="nav-btn px-2"
                  outline
                  color="primary"
                  onClick={() => handleWindowChange(PREV)}
                >
                  <ChevronLeft size={16} /> Back
                </Button>
              </Col>
              <Col className={generateClassNameForContentPlacement('end')}>
                {((!!customer && !!customerName) || message?.color === 'success') && (
                  <Button
                    className="nav-btn next px-2"
                    color="success"
                    onClick={async () => {
                      if (isEmpty(customer)) await handleAddNewCustomer();
                      if (!isEmpty(customer)) handleWindowChange(NEXT);
                    }}
                  >
                    <span
                      style={{
                        paddingRight: '10px',
                      }}
                    >
                      Next
                    </span>
                    <ChevronRight size={16} />
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CustomerInformation;
